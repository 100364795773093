import axios from 'axios'
import { projectAuth } from '../../Auth/firebase_config'
import { baseUrl } from '../config'
import { populateIndex } from '../../../helpers/generalHelpers'

/**
 * Add file, images for intake form answers:
 *
 * @return {Promise<any>}
 */
const addIntakeFormFile = (patientId, file) => {
    return new Promise((resolve, reject) => {
        projectAuth.currentUser
            .getIdToken(true)
            .then(function (idToken) {
                const reqBody = {
                    headers: {
                        Authorization: `${idToken}`,
                        Accept: 'application/json',
                        'Content-Type': `multipart/form-data; boundary=${file._boundary}`,
                    },
                }

                axios
                    .put(
                        `${baseUrl}/patient/${patientId}/intakeForms/files`, 
                        file, 
                        reqBody
                    )
                    .then((res) => resolve(res.data))
                    .catch((err) => reject(JSON.stringify(err)))
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export default addIntakeFormFile
