import React, { useState, Suspense, lazy, useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import * as ROUTES from './constants/routes'

// React components
import PrivateRoute from './components/PrivateRoute'
import LoadingSpinnerCSS from './components/utils/LoadingSpinnerCSS'
import useAppData from './hooks/useAppData'
import FeedbackButton from './elements/buttons/FeedbackButton'

// Firebase
import { useCurrentUser } from './hooks/useCurrentUser'

// Contexts: To enable information-sharing between siblings
import PatientsContext from './contexts/PatientsContext'
import ResponsiveDrawerMenu from './components/ResponsiveDrawerMenu'
import TempDrop from './components/Account/TempDrop'
import OuraIntegration from './components/Account/OuraIntegration'
import MyFitnessIntegration from './components/Account/myFitnessIntegration'
import Integrations from './pages/Integrations'

// Tracking Modal:
import Tracking from './elements/modal/trackingModal'
import IncomingInvite from './elements/modal/incomingInvite'

const PatientsProvider = PatientsContext.Provider

// Pages
const Account = lazy(() => import('./pages/Account'))
const Chat = lazy(() => import('./pages/ChatService'))
const Error = lazy(() => import('./pages/Error'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const History = lazy(() => import('./pages/History'))
const Landing = lazy(() => import('./pages/Landing'))
const Notifications = lazy(() => import('./pages/Notifications'))
const Programs = lazy(() => import('./pages/Programs'))
const Recommendations = lazy(() => import('./pages/Recommendations'))
const Signin = lazy(() => import('./pages/Signin'))
const Signup = lazy(() => import('./pages/Signup'))
const Subscriptions = lazy(() => import('./pages/Subscriptions'))
const Courses = lazy(()=>import('./pages/course'))
const Files  = lazy(()=>import('./pages/File'))
const IntakeForm = lazy(()=>import('./pages/IntakeForm'))
const HealthHistory = lazy(()=>import('./pages/HealthHistory'))
const UserNotification = lazy(()=>import('./pages/UserNotification'))


const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(6),
    },
}))

function App() {
    const {
        state,
        refresh,
        tracking,
        shownNotification,
        setShownNotification,
        setUser,
        setTracking,
        acceptInvite,
        setAcceptInvite,
        handleUpdateCycleEntry,
        handleDeleteCycleEntry,
        handleUpdateCoverLine,
        handleUpdateBBT,
        handleNutritionAdd,
        handleNutritionAddFirebase,
        handleNutritionDelete,
        handleNutritionDeleteFirebase,
        handleNutritionUpdate,
        handleSigninWithGoogle,
        handleSigninWithFacebook,
        handleSyncGoogleFit,
        handleSyncFitbit,
        handleRevokeFitbit,
        refreshAppData
    } = useAppData()
    const { user } = state
    console.log('user', user)
    const classes = useStyles()



    useCurrentUser(setUser)

    const signOutApp = useCallback(() => {
        setUser({ loggedIn: false })
    }, [setUser])

    const sideBarItems = !user.isAuth
        ? [{ text: 'ACCOUNT', route: ROUTES.ACCOUNT }]
        : !user.practitioner
            ? [
                { text: 'HEALTH DATA', route: ROUTES.HISTORY },
                { text: 'PROGRAMS', route: ROUTES.PROGRAMS },
                { text: 'Files', route: ROUTES.FILES},
                { text: 'Notifications', route: ROUTES.USER_NOTIFICATION, showDot: shownNotification },
                { text: 'HEALTH JOURNEY', route: ROUTES.HEALTH_HISTORY},
                { text: 'INVITATIONS', route: ROUTES.NOTIFICATION },
                { text: 'Courses', route: ROUTES.MY_COURSES},
                { text: 'SUBSCRIPTIONS', route: ROUTES.SUBSCRIPTIONS },
                { text: 'ACCOUNT', route: ROUTES.ACCOUNT },
                { text: 'INTEGRATIONS', route: ROUTES.INTEGRATIONSPAGE },
            ]
            : [
                { text: 'HEALTH DATA', route: ROUTES.HISTORY },
                { text: 'CHAT', route: ROUTES.CHAT },
                { text: 'PROGRAMS', route: ROUTES.PROGRAMS },
                { text: 'RECOMMEND', route: ROUTES.RECOMMENDATIONS },
                { text: 'Files', route: ROUTES.FILES},
                { text: 'Intake Forms', route: ROUTES.INTAKE_FORM },
                { text: 'Notifications', route: ROUTES.USER_NOTIFICATION, showDot: shownNotification },
                { text: 'HEALTH JOURNEY', route: ROUTES.HEALTH_HISTORY},
                { text: 'INVITATIONS', route: ROUTES.NOTIFICATION },
                { text: 'Courses', route: ROUTES.MY_COURSES},
                { text: 'SUBSCRIPTIONS', route: ROUTES.SUBSCRIPTIONS },
                { text: 'ACCOUNT', route: ROUTES.ACCOUNT },
                { text: 'INTEGRATIONS', route: ROUTES.INTEGRATIONSPAGE },
            ]

    return (
        <Router>
            <div style={{ display: 'flex' }}>
                {user.isAuth && (
                    <ResponsiveDrawerMenu
                        list={sideBarItems}
                        signOutApp={signOutApp}
                    />
                )}
                <main className={classes.content} id="main-content-wrapper">
                   { 
                    user?.data?.tracking
                      &&
                    <Tracking 
                        open={tracking}
                        onClose={()=>setTracking(false)}
                        tracking={user?.data?.tracking}
                        patientId={user.uid}
                        practitionerId={user?.pracId}
                        practitionerName={user?.practitioner?.firstName}
                    />
                    }
                    {
                    user?.data?.acceptInvite
                        &&
                    <IncomingInvite 
                        open={acceptInvite}
                        onClose={()=>{
                            setAcceptInvite(false)
                        }}
                        user={user}
                        data = {user?.data?.acceptInvite}
                    />

                    }
                    <div className={classes.toolbar} />
                    <Suspense fallback={<LoadingSpinnerCSS />}>
                        <Switch>
                            {/* Public Routes */}
                            <Route
                                exact
                                path={ROUTES.LANDING}
                                render={() => (
                                    <Landing isAuthenticated={user.isAuth} />
                                )}
                            />
                            <Route
                                path={ROUTES.SIGN_IN}
                                render={() => (
                                    <Signin
                                        isAuthenticated={user.isAuth}
                                        handleSigninWithGoogle={
                                            handleSigninWithGoogle
                                        }
                                        handleSigninWithFacebook={
                                            handleSigninWithFacebook
                                        }
                                    />
                                )}
                            />
                            <Route
                                path={ROUTES.SIGN_UP}
                                render={() => (
                                    <Signup isAuthenticated={user.isAuth} />
                                )}
                            />
                            <Route
                                path={ROUTES.PASSWORD_FORGET}
                                render={() => (
                                    <ForgotPassword
                                        isAuthenticated={user.isAuth}
                                    />
                                )}
                            />
                            {user.isAuth && (
                                <Route
                                    path={ROUTES.HEALTH_HISTORY}
                                    render={() => (
                                        <PatientsProvider value={user}>
                                            <HealthHistory />
                                        </PatientsProvider>
                                    )}
                                />
                            )}

                            {/* Private Routes */}
                            {user.isAuth && (
                                <PrivateRoute
                                    path={ROUTES.PROGRAMS}
                                    component={() => (
                                        <PatientsProvider value={user}>
                                            <Programs />
                                        </PatientsProvider>
                                    )}
                                />
                            )}
                            {user.isAuth && (
                                <PrivateRoute
                                    path={ROUTES.MY_COURSES}
                                    component={() => (
                                        <PatientsProvider value={user}>
                                            <Courses />
                                        </PatientsProvider>
                                    )}
                                />
                            )}

                            {user.isAuth && (
                                <PrivateRoute
                                    path={ROUTES.FILES}
                                    component={() => (
                                        <PatientsProvider value={user}>
                                            <Files />
                                        </PatientsProvider>
                                    )}
                                />
                            )}

                            {user.isAuth && (
                                <PrivateRoute
                                    path={ROUTES.INTAKE_FORM}
                                    component={() => (
                                        <PatientsProvider value={user}>
                                            <IntakeForm />
                                        </PatientsProvider>
                                    )}
                                />
                            )}

                            {user.isAuth && (
                                <PrivateRoute
                                    path={ROUTES.RECOMMENDATIONS}
                                    component={() => (
                                        <PatientsProvider value={user}>
                                            <Recommendations />
                                        </PatientsProvider>
                                    )}
                                />
                            )}

                            {user.isAuth && (
                                <PrivateRoute
                                    path={ROUTES.USER_NOTIFICATION}
                                    component={() => (
                                        <PatientsProvider value={user}>
                                            <UserNotification 
                                                shownNotification={shownNotification}
                                                setShownNotification={setShownNotification}
                                            />
                                        </PatientsProvider>
                                    )}
                                />
                            )}

                            {user.isAuth && (
                                <PrivateRoute
                                    path={ROUTES.HISTORY}
                                    component={() => (
                                        <PatientsProvider value={user}>
                                            <History
                                                refresh={refresh}                                        
                                                handleUpdateCycleEntry={
                                                    handleUpdateCycleEntry
                                                }
                                                handleDeleteCycleEntry={
                                                    handleDeleteCycleEntry
                                                }
                                                handleUpdateCoverLine={
                                                    handleUpdateCoverLine
                                                }
                                                handleUpdateBBT={
                                                    handleUpdateBBT
                                                }
                                                handleNutritionAdd={
                                                    handleNutritionAdd
                                                }
                                                handleNutritionAddFirebase={
                                                    handleNutritionAddFirebase
                                                }
                                                handleNutritionDelete={
                                                    handleNutritionDelete
                                                }
                                                handleNutritionDeleteFirebase={
                                                    handleNutritionDeleteFirebase
                                                }
                                                handleNutritionUpdate={
                                                    handleNutritionUpdate
                                                }
                                                refreshAppData={refreshAppData} 
                                            />
                                        </PatientsProvider>
                                    )}
                                />
                            )}

                            {user.isAuth && (
                                <PrivateRoute
                                    path={ROUTES.NOTIFICATION}
                                    component={() => (
                                        <PatientsProvider value={user}>
                                            <Notifications />
                                        </PatientsProvider>
                                    )}
                                />
                            )}

                            {user.isAuth && (
                                <PrivateRoute
                                    path={ROUTES.SUBSCRIPTIONS}
                                    component={() => (
                                        <PatientsProvider value={user}>
                                            <Subscriptions />
                                        </PatientsProvider>
                                    )}
                                />
                            )}

                            {user.isAuth && (
                                <PrivateRoute
                                    path={ROUTES.CHAT}
                                    component={() => (
                                        <PatientsProvider value={user}>
                                            <Chat />
                                        </PatientsProvider>
                                    )}
                                />
                            )}
                            {user.isAuth && (
                                <PrivateRoute
                                    path={ROUTES.TEMPDROP}
                                    component={() => (
                                        <PatientsProvider value={user}>
                                            <TempDrop user={user} refreshAppData={refreshAppData} />
                                        </PatientsProvider>
                                    )}
                                />
                            )}
                            {user.isAuth && (
                                <PrivateRoute
                                    path={ROUTES.OURA}
                                    component={() => (
                                        <PatientsProvider value={user}>
                                            <OuraIntegration user={user} refreshAppData={refreshAppData} />
                                        </PatientsProvider>
                                    )}
                                />
                            )}

                            {user.isAuth && (
                                <PrivateRoute
                                    path={ROUTES.MY_FITNESS_PAL}
                                    component={() => (
                                        <PatientsProvider value={user}>
                                            <MyFitnessIntegration user={user} refreshAppData={refreshAppData} />
                                        </PatientsProvider>
                                    )}
                                />
                            )}

                            {user.isAuth && (
                                <PrivateRoute
                                    path={ROUTES.ACCOUNT}
                                    component={() => (
                                        <PatientsProvider value={user}>
                                            <Account
                                                handleSyncGoogleFit={
                                                    handleSyncGoogleFit
                                                }
                                                handleSyncFitbit={
                                                    handleSyncFitbit
                                                }
                                                handleRevokeFitbit={
                                                    handleRevokeFitbit
                                                }
                                                user={user}
                                            />
                                        </PatientsProvider>
                                    )}
                                />
                            )}
                            {user.isAuth && (
                                <PrivateRoute
                                    path={ROUTES.INTEGRATIONSPAGE}
                                    component={() => (
                                        <PatientsProvider value={user}>

                                            <Integrations
                                                handleSyncGoogleFit={
                                                    handleSyncGoogleFit
                                                }
                                                handleSyncFitbit={
                                                    handleSyncFitbit
                                                }
                                                handleRevokeFitbit={
                                                    handleRevokeFitbit
                                                }
                                                user={user}
                                            />
                                        </PatientsProvider>

                                    )}
                                />
                            )}

                            {/* Error Fallback */}
                            <Route
                                component={() => (
                                    <Error isAuthenticated={user.isAuth} />
                                )}
                            />
                        </Switch>
                    </Suspense>
                    {/* Feedback Speed Dial Button */}
                    <FeedbackButton />
                </main>
            </div>
        </Router>
    )
}

export default App
